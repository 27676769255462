import React, { useState, useEffect, useContext } from 'react';
import { message, Select } from 'antd';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './PayrollSetup.css';
import { DepartmentsContext } from './DepartmentsContext';
import { HomeFilled } from '@ant-design/icons';
import AdminNavbar from './components/AdminNavbar';


const PayrollSetup = () => {
	const [payrollType, setPayrollType] = useState('');
	const [ranges, setRanges] = useState([]);
	const [companyId, setCompanyId] = useState(null);
	const [payrollSetups, setPayrollSetups] = useState({'ppd': [], 'ppp': []});
	const [departments, setDepartments] = useState([]);
	const { selectedDepartments, setSelectedDepartments } = useContext(DepartmentsContext);
	const [companyName, setCompanyName] = useState(null);
	const [showPPO, setShowPPO] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		fetchCompany();
		fetchDepartments();
	}, []);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			console.log('Company Data:', data);	
			if (response.ok) {
				setCompanyId(data.company._id);

				setCompanyName(data.company.companyName);
				fetchDepartments(data.company.companyName);
				// // console.log("companyName", companyName);
			} else {
				message.error('Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};


	const fetchDepartments = async (companyName) => {
		if (!companyName) {
			return; // Return early if companyName is falsy
		}
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getDepartmentsE/${companyName}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			// // console.log('Departments Data:', data);
			if (response.ok) {
				const departments = data; // Assign the response data directly
				setDepartments(departments);
			} else {
				console.error('Failed to fetch departments:', data.error);
				// throw new Error(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};

	useEffect(() => {
		// Call fetchCompany on component mount
		fetchCompany();
		if (companyId) {
			fetchPayrollSetup();
		}
	}, [companyId]);

	const fetchPayrollSetup = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getPayRoll/${companyId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch payroll setup');
				return
				// throw new Error('Failed to fetch payroll setup');
			}

			const data = await response.json();
			// console.log('Payroll Setup:', data);
			if (!data.payrollSetup || data.payrollSetup.length === 0) {
				message.error('Payroll setups are empty');
				return
				// throw new Error('Payroll setups are empty');
			}
			const payrollDict = data.payrollSetup.reduce((acc, { payrollType, ranges }) => {
				acc[payrollType] = ranges; // Set type as key and ranges as value
				return acc;
			}, {});
			setPayrollSetups(payrollDict);


			// Redirect to SalariesAndPayroll page if the payroll type is "fixed"
			if (data.payrollSetup.payrollType === 'fixed' && data.payrollSetup.departments.length > 0) {
				navigate(`/salariesandpayroll/fixed/${selectedDepartments.join(',')}`);
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch payroll setups');
		}
	};

	const handlePayrollTypeChange = (event) => {
		setPayrollType(event.target.value);
		setRanges([]);
	};

	const handleAddRange = () => {
		setRanges([...ranges, { start: '', end: '', rate: '' }]);
	};

	const handleRangeChange = (index, field, value) => {
		const updatedRanges = [...ranges];
		updatedRanges[index][field] = value;
		setRanges(updatedRanges);
	};

	const handleDepartmentChange = (departmentId) => {
		setSelectedDepartments((prevSelectedDepartments) => {
			const updatedDepartments = [...prevSelectedDepartments];
			const departmentIndex = updatedDepartments.indexOf(departmentId);
			if (departmentIndex > -1) {
				updatedDepartments.splice(departmentIndex, 1);
			} else {
				updatedDepartments.push(departmentId);
			}
			return updatedDepartments;
		});
	};


	const handleApply = async () => {
		if (selectedDepartments.length === 0) {
			message.error('Please select at least one department');
			return;
		}

		try {
			const token = localStorage.getItem('token');

			if (!companyId) {
				message.error('Company not found');
				return;
			}

			const payrollData = {
				companyId,
				payrollSetup: {
					payrollType,
					ranges: [...ranges],
					departments: [...selectedDepartments],
				},
			};

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/savePayRoll', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payrollData),
			});

			const data = await response.json();
			if (response.ok) {
				message.success('Payroll setup applied successfully');
				// Process the response data or perform any necessary actions
			} else {
				message.error(data.error || 'Failed to save payroll setup');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save payroll setup');
		}
	};
	const handleSave = async () => {
		try {
			const token = localStorage.getItem('token');

			if (!companyId) {
				message.error('Company not found');
				return;
			}

			for (const range of ranges) {
				for (const payroll of payrollSetups[payrollType]) {
					if (range.start >= payroll.start && range.start <= payroll.end) {
						message.error(`Rate for ${range.start} already exists`);
						return
					}
					if (range.end >= payroll.start && range.end <= payroll.end) {
						message.error(`Rate for ${range.end} already exists`);
						return
					}
				}
			}

			const payrollData = {
				payrollSetup: {
					payrollType,
					ranges: [...ranges],
				},
			};

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/savePayRoll', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payrollData),
			});

			const data = await response.json();
			if (response.ok) {
				message.success('Payroll setup saved successfully');
				fetchPayrollSetup();
				// Process the response data or perform any necessary actions
			} else {
				message.error(data.error || 'Failed to save payroll setup');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save payroll setup');
		}
	};

	const handleShowPPO = () => {
		setShowPPO(showPPO? false : true);
	}

	return (
		<div className=''>
			<AdminNavbar />
			<div className='col-md-7' style={{padding: '3rem 5rem'}} >

				<div className='payroll-type'>
					<label htmlFor="payrollType"></label>
					<select id="payrollType" value={payrollType} style={{ padding: '1vh 3vw' }} onChange={handlePayrollTypeChange}>
						<option value="">Choose Payroll Type</option>
						<option value="ppp">PPP (Pay per Pickup)</option>
						<option value="ppd">PPD (Pay per Delivery)</option>
						<option value="fixed">Fixed Salary</option>
					</select>
				</div>
				<br />

				{payrollType === 'fixed' && departments.length > 0 && (
					<div className='payroll-department'>
						<p style={{ fontWeight: '800' }}>Select Departments:</p>
						{departments.map((department) => (
							<div key={department._id}>
								<label>
									<input
										type="checkbox"
										checked={selectedDepartments.includes(department._id)}
										onChange={() => handleDepartmentChange(department._id)}
									/>
									{department.departmentName}
								</label>
							</div>
						))}
					</div>
				)}

				{(payrollType === 'ppd' || payrollType === 'ppp') && (
					<div>
						<button className='custombutton' onClick={handleAddRange}>Add Range</button>
						<button className='custombutton' onClick={handleShowPPO}>{showPPO ? 'Hide' : 'Show'} Existing Ranges</button>
						{ranges.map((range, index) => (
							<div className='inputdate' key={index}>
								<input
									type="number"
									placeholder="Start"
									value={range.start}
									onChange={(e) => handleRangeChange(index, 'start', e.target.value)}
								/>
								<input
									type="number"
									placeholder="End"
									value={range.end}
									onChange={(e) => handleRangeChange(index, 'end', e.target.value)}
								/>
								<input
									type="number"
									placeholder="Rate"
									value={range.rate}
									onChange={(e) => handleRangeChange(index, 'rate', e.target.value)}
								/>
							</div>
						))}
					</div>
				)}

				{payrollType === 'fixed' && selectedDepartments.length > 0 && (
					<button className='primary-button' onClick={handleApply}>Apply</button>
				)}

				{payrollType !== 'fixed' && (
					<button className='primary-button' onClick={handleSave}>Save</button>
				)}
			{payrollSetups[payrollType]?.length > 0 && showPPO && (
				<div style={{paddingTop:'17vh'}}>
					<h3 style={{textAlign: 'left', paddingBottom: '10px'}}>Existing Payroll Setups:</h3>
					<ul>
					{payrollSetups[payrollType].map((range, index) => (
						<li key={index}>
							Payroll Type: {range.start} - {range.end} (PPO: {range.rate})
						</li>
					))}
					</ul>
				</div>
			)}
			</div>
			{/* <p className='home-header' style={{ top: '40px', left: '20px' }}>
				<Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
				Payroll Setup
			</p> */}
		</div>
	);
};

export default PayrollSetup;
