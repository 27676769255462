import { Button, Card, Col, DatePicker, Input, Row, Select, Statistic, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { read, utils, write } from 'xlsx';
import { DepartmentsContext } from './DepartmentsContext';
import './Salaries&Payroll.css';
import AdminNavbar from './components/AdminNavbar';

const { MonthPicker } = DatePicker;
const { Option } = Select;

const SalariesAndPayroll = ({ selectedEmployees }) => {
	const [file, setFile] = useState(null);
	const [employees, setEmployees] = useState([]);
	const [fileImported, setFileImported] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [payrollSetup, setPayrollSetup] = useState({'ppp': [], 'ppd': []});
	const [companyId, setCompanyId] = useState(null);
	const [totalOrders, setTotalOrders] = useState(0);
	const [totalSickLeaves, setTotalSickLeaves] = useState(0);
	const [totalDeductions, setTotalDeductions] = useState(0);
	const [totalPayables, setTotalPayables] = useState(0);
	const [totalCompanyDeductionCollection, setTotalCompanyDeductionCollection] = useState(0);
	const [totalClicked, setTotalClicked] = useState(false);
	const [payrollType, setPayrollType] = useState('PPO');
	const { selectedDepartments } = useContext(DepartmentsContext);
	const fileInputRef = useRef(null);
	const tableContainerRef = useRef(null);
	// // console.log('Selected Departments:', selectedDepartments);

	const [isFixedPayroll, setIsFixedPayroll] = useState(false);
	const filteredEmployees = employees.filter((employee) =>
		selectedDepartments.includes(employee.department)
	);

	const handlePayrollTypeChange = (value) => {
		if (value === 'Fixed') {
			setIsFixedPayroll(true);
			const selectedDepartmentIds = selectedDepartments.map((department) => department.value);

			fetchEmployees(selectedDepartmentIds);
		} else {
			setIsFixedPayroll(false);
			setEmployees([]);
		}
		setPayrollType(value);
	};

	useEffect(() => {
		if (payrollType === 'Fixed') {
			fetchEmployees(selectedDepartments.map((department) => department.value));
		}
	}, [selectedDepartments, payrollType]);

	const fetchEmployees = async (selectedDepartmentIds) => {
		try {
			const token = localStorage.getItem('token');

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getEmployeesByDepartments', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ selectedDepartmentIds }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employees');
				return
				// throw new Error('Failed to fetch employees');
			}

			const data = await response.json();
			return data; // Return the fetched employees
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	};

	useEffect(() => {
		if (payrollType === 'Fixed') {
			const selectedDepartmentIds = selectedDepartments.map((department) => department.value);
			fetchEmployees(selectedDepartmentIds).then((data) => {
				setEmployees(data); // Set employees outside of the fetchEmployees function
			});
		}
	}, [selectedDepartments, payrollType]);

	const payrollColumns = {
		PPO: [
			{
				title: 'Company Number',
				dataIndex: 'companyNumber',
				key: 'companyNumber',
				width: '200px'
			},
			{
				title: 'eCode',
				dataIndex: 'eCode',
				key: 'eCode',
				width: '100px'
			},
			{
				title: 'Riders Name',
				dataIndex: 'employeeName',
				key: 'employeeName',
				width: '400px'
			},
			{
				title: 'EMPL Status',
				dataIndex: 'status',
				key: 'status',
				width: '150px'
			},
			{
				title: 'QID Number',
				dataIndex: 'qidNumber',
				key: 'qidNumber',
				width: '175px'
			},
			{
				title: 'VISA',
				dataIndex: 'visaNumber',
				key: 'visaNumber',
				width: '200px'
			},
			{
				title: 'IBAN Number',
				dataIndex: 'bankAccountIBAN',
				key: 'bankAccountIBAN',
				width: '350px'
			},

			{
				title: 'PPP',
				dataIndex: 'ppp',
				key: 'ppp',
				width: '75px'
			},
			{
				title: 'PICKUPS',
				dataIndex: 'pickups',
				key: 'pickups',
				width: '100px'
			},
			{
				title: 'Pickup Benefits',
				dataIndex: 'pickupBenefits',
				key: 'pickupBenefits',
				width: '150px'
			},
			{
				title: 'PPD',
				dataIndex: 'ppd',
				key: 'ppd',
				width: '75px'
			},
			{
				title: 'DELIVERIES',
				dataIndex: 'deliveries',
				key: 'deliveries',
				width: '100px'
			},
			{
				title: 'Delivery Benefits',
				dataIndex: 'deliveryBenefits',
				key: 'deliveryBenefits',
				width: '150px'
			},
			{
				title: 'SICK LEAVE',
				dataIndex: 'sickLeave',
				key: 'sickLeave',
				width: '150px'
			},
			{
				title: 'OT/Addition',
				dataIndex: 'overtimeAdditions',
				key: 'overtimeAdditions',
				width: '150px'
			},
			{
				title: 'LLD',
				dataIndex: 'lateLoginDeduction',
				key: 'lateLoginDeduction',
				width: '75px'
			},
			{
				title: 'No Show',
				dataIndex: 'noShow',
				key: 'noShow',
				width: '100px'
			},
			{
				title: 'Penalties',
				dataIndex: 'penalties',
				key: 'penalties',
				width: '150px'
			},
			{
				title: 'company C/D',
				dataIndex: 'talabatInvoice',
				key: 'talabatInvoice',
				width: '150px'
			},
			{
				title: 'ALTM',
				dataIndex: 'advancesLoansTrafficMobile',
				key: 'advancesLoansTrafficMobile',
				width: '100px'
			},
			{
				title: 'Total Benefits',
				dataIndex: 'totalBenefits',
				key: 'totalBenefits',
				width: '150px'
			},
			{
				title: 'Total Deductions',
				dataIndex: 'totalDeductions',
				key: 'totalDeductions',
				width: '200px'
			},
			{
				title: 'Total Payables',
				dataIndex: 'totalPayables',
				key: 'totalPayables',
				width: '200px'
			},
		],
		PPH: [
			// Add columns for PPH payroll type here
		],
		PPD: [
			// Add columns for PPD payroll type here
		],
		Fixed: [
			{
				title: 'S.No',
				dataIndex: 'sNo',
				key: 'sNo',
				width: '75px'
			},
			{
				title: 'Emp Code',
				dataIndex: 'eCode',
				key: 'eCode',
				width: '100px'
			},
			{
				title: 'Name',
				dataIndex: 'employeeName',
				key: 'employeeName',
				width: '400px'
			},
			{
				title: 'Custom Attributes',
				dataIndex: 'customAttributes',
				key: 'customAttributes',
				render: customAttributes => (
					<div>
						{customAttributes.map((attr, index) => (
							<p key={index}>
								{attr.value}
							</p>
						))}
					</div>
				),
				width: '300px'
			},

			{
				title: 'Days Worked',
				dataIndex: 'daysWorked',
				key: 'daysWorked',
				width: '150px'
			},
			{
				title: 'Basic Salary',
				dataIndex: 'basicSalary',
				key: 'basicSalary',
				width: '100px'
			},
			{
				title: 'Additional / Bonus',
				dataIndex: 'additionalBonus',
				key: 'additionalBonus',
				width: '150px'
			},
			{
				title: 'Company Deduction',
				dataIndex: 'companyDeduction',
				key: 'companyDeduction',
				width: '150px'
			},
			{
				title: 'Net Salary',
				dataIndex: 'netSalary',
				key: 'netSalary',
				width: '150px'
			},
		],
	};

	useEffect(() => {
		fetchCompany();
	}, []);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					setCompanyId(companyId);
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};

	useEffect(() => {
		if (companyId) {
			fetchPayrollSetup();
		}
	}, [companyId]);

	const fetchPayrollSetup = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getPayRoll/${companyId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch payroll setup');
				return
				// throw new Error('Failed to fetch payroll setup');
			}

			const data = await response.json();
			// console.log('Payroll Setup:', data);
			if (!data.payrollSetup || data.payrollSetup.length === 0) {
				message.error('Payroll setups are empty');
				return
				// throw new Error('Payroll setups are empty');
			}
			const payrollDict = data.payrollSetup.reduce((acc, { payrollType, ranges }) => {
				acc[payrollType] = ranges; // Set type as key and ranges as value
				return acc;
			}, {});
			setPayrollSetup(payrollDict);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch payroll setup');
		}
	};

	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
		event.target.value = null;
	};

	useEffect(() => {
		if (file) {
			importFile();
		}
	}, [file]);

	const importFile = () => {

		if (payrollType === 'Fixed') {
			message.error('Only valid for PPO');
			return;
		}
		if (!file) {
			message.error('Please select a file to import');
			return;
		}
		// if (!Array.isArray(payrollSetup)) {
		// 	message.error('Payroll setup is not available');
		// 	return;
		// }
		try {
			const reader = new FileReader();

			reader.onload = async (evt) => {
				const bstr = evt.target.result;
				const wb = read(bstr, { type: 'binary' });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const data = utils.sheet_to_json(ws, { header: 1 });
				let eCodes = data.map((row) => row[0]);
				const token = localStorage.getItem('token');
				const response = await fetch('https://hrms-5u7j.onrender.com/admin/getByECode', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + token,

					},
					body: JSON.stringify({ eCodes }),
				});

				if (!response.ok) {
					message.error('Failed to fetch employees');
					return
					// throw new Error('Failed to fetch employees');
				}

				let fetchedEmployees = await response.json();

				const employeeMap = fetchedEmployees.reduce((map, employee) => {
					map[employee.eCode] = employee;
					return map;
				}, {});
				eCodes = eCodes.filter((eCode) => employeeMap.hasOwnProperty(eCode));

				const mergedEmployees = eCodes.map((eCode) => {
					const employee = employeeMap[eCode];
					const fileData = data.find((row) => row[0] === eCode);
					const pickups = fileData[1];
					const deliveries = fileData[2];
					const sickLeave = fileData[3];
					const overtimeAdditions = fileData[4];
					const lateLoginDeduction = fileData[5];
					const noShow = fileData[6];
					const penalties = fileData[7];
					const talabatInvoice = fileData[8];
					const advancesLoansTrafficMobile = fileData[9];
					const rangePPP = payrollSetup['ppp']?.find(({ start, end }) => pickups >= start && pickups <= end);
					const ppp = rangePPP ? rangePPP.rate : 0;
					const rangePPD = payrollSetup['ppd']?.find(({ start, end }) => deliveries >= start && deliveries <= end);
					const ppd = rangePPD ? rangePPD.rate : 0;

					const totalBenefits = (ppp * pickups) + (ppd * deliveries) + (overtimeAdditions || 0) + (sickLeave || 0);

					const totalDeductions =
						(lateLoginDeduction || 0) +
						(noShow || 0) +
						(penalties || 0) +
						(talabatInvoice || 0) +
						(advancesLoansTrafficMobile || 0);
					const totalPayables = totalBenefits - totalDeductions;

					return {
						companyNumber: employee.refNo,
						eCode: eCode,
						employeeName: employee.employeeName,
						status: employee.status,
						qidNumber: employee.qidNumber,
						visaNumber: employee.visaNumber,
						bankAccountIBAN: employee.bankAccountIBAN,
						ppp,
						pickups,
						pickupBenefits: ppp * pickups,
						ppd,
						deliveries,
						deliveryBenefits: ppd * deliveries,
						sickLeave,
						overtimeAdditions,
						lateLoginDeduction,
						noShow,
						penalties,
						talabatInvoice,
						advancesLoansTrafficMobile,
						totalBenefits,
						totalDeductions,
						totalPayables,
					};

				});

				setEmployees(mergedEmployees);
				setFileImported(true);

				message.success('Employee details retrieved successfully!');
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to import employee details');
		}
	};

	const handleImportSalaryClick = async () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleMonthChange = (date) => {
		setSelectedMonth(date);
	};

	const generateFileName = () => {
		if (selectedMonth) {
			const month = selectedMonth.month() + 1; // Month is zero-based
			const year = selectedMonth.year();
			return `${month}-${year}`;
		}
		return '';
	};

	const handleSaveClick = async () => {
		if (!selectedMonth) {
			message.error('Please select a month');
			return;
		}

		const fileName = generateFileName();
		// // console.log('fileName:', fileName);
		if (!fileName) {
			message.error('Invalid month selection');
			return;
		}

		if (!companyId) {
			message.error('Company ID is not available');
			return;
		}

		try {
			const ws = utils.json_to_sheet(employees);
			// // console.log('Worksheet data:', ws);

			const wb = utils.book_new();
			utils.book_append_sheet(wb, ws, 'Sheet1');
			const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
			// // console.log('Workbook binary data:', wbout);

			const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
			// // console.log("bob", blob);
			const formData = new FormData();
			formData.append('file', blob, `${fileName}.xlsx`);
			// formData.append('companyName', fileName);
			formData.append('companyId', companyId);

			// // console.log('formData:', formData);
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileSalaries', {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: formData,
			});

			if (!response.ok) {
				message.error('Failed to save the file');
				return;
				// throw new Error('Failed to save the file');
			}

			message.success('File saved successfully!');
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save the file');
		}
	};
	function s2ab(s) {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	}



	const handleInputChange = (event, index, columnName) => {
		const { value } = event.target;
		const parsedValue = parseInt(value, 10);

		setEmployees((prevEmployees) => {
			const updatedEmployees = [...prevEmployees];
			const employee = { ...updatedEmployees[index] };

			if (columnName === 'orders' || columnName === 'sickLeave' || columnName === 'overtimeAdditions') {
				employee[columnName] = parsedValue;
			} else {
				employee[columnName] = value;
			}

			const orders = employee.orders || 0;
			const ppo = employee.ppo || 0;
			const overtimeAdditions = employee.overtimeAdditions || 0;
			const sickLeave = employee.sickLeave || 0;

			employee.totalBenefits = ppo * orders + overtimeAdditions + sickLeave;

			const lateLoginDeduction = parseInt(employee.lateLoginDeduction, 10) || 0;
			const noShow = parseInt(employee.noShow, 10) || 0;
			const penalties = parseInt(employee.penalties, 10) || 0;
			const talabatInvoice = parseInt(employee.talabatInvoice, 10) || 0;
			const advancesLoansTrafficMobile = parseInt(employee.advancesLoansTrafficMobile, 10) || 0;

			employee.totalDeductions = lateLoginDeduction + noShow + penalties + talabatInvoice + advancesLoansTrafficMobile;
			employee.totalPayables = employee.totalBenefits - employee.totalDeductions;

			updatedEmployees[index] = employee;
			return updatedEmployees;
		});
	};

	useEffect(() => {
		calculateTotals();
	}, [employees]);

	const calculateTotals = () => {
		let totalOrders = 0;
		let totalSickLeaves = 0;
		let totalDeductions = 0;
		let totalPayables = 0;
		let totalCompanyDeductionCollection = 0;

		employees.forEach((employee) => {
			totalOrders += employee.pickups + employee.deliveries;
			totalSickLeaves += employee.sickLeave || 0;
			totalDeductions += employee.totalDeductions || 0;
			totalPayables += employee.totalPayables || 0;
			totalCompanyDeductionCollection += employee.talabatInvoice || 0;
		});

		setTotalOrders(totalOrders);
		setTotalSickLeaves(totalSickLeaves);
		setTotalDeductions(totalDeductions);
		setTotalPayables(totalPayables);
		setTotalCompanyDeductionCollection(totalCompanyDeductionCollection);
	};

	const handleTotalClick = () => {
		setTotalClicked(!totalClicked);
	};

	const Tablescroll = () => {
		const container = tableContainerRef.current;
		if (container) {
			const table = container.querySelector('table');
			const element = document.getElementById('blur');
			if (table) {
				if (container.scrollLeft + container.clientWidth >= table.clientWidth - 50) {
					element.className = '';
				} else {
					element.className = 'blur-right';
				}
			}
		}
	};

	return (
		<>
			<AdminNavbar />
			<div className='salaries-payroll mt-3'>
				{/* <p className='home-header' style={{ top: '40px', left: '20px' }}>
					<Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
					Earnings Dashboard
				</p> */}
				<div><br />
					<input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />
					<Button style={{ backgroundColor: "#6FB555", padding: '0 2vw' }} type="primary" onClick={handleImportSalaryClick}>
						Import Employee Details
					</Button>
				</div>
				<br></br>
				<>
					<MonthPicker
						placeholder="Select a month"
						onChange={handleMonthChange}
						style={{ marginRight: '16px' }}
						allowClear={false}
					/>
					<Button onClick={handleSaveClick} disabled={!selectedMonth}>
						Save
					</Button>
					<Select value={payrollType} onChange={handlePayrollTypeChange} style={{ marginLeft: '16px', width: '120px' }}>
						<Option value="PPO">PPO</Option>
						<Option value="PPH">PPH</Option>
						<Option value="PPD">PPD</Option>
						<Option value="Fixed">Fixed</Option>
					</Select>
				</>
				{(fileImported || (payrollType === 'Fixed' && isFixedPayroll)) && (
					<div className={`total-section ${totalClicked ? 'clicked' : ''}`} onClick={handleTotalClick}>
						<br /><br />
						<Row gutter={16} style={{ margin: '0' }}>
							<Col span={4}>
								<Card bordered={false}>
									<Statistic
										title='Total Orders'
										value={totalOrders}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card bordered={false}>
									<Statistic
										title='Total Sick Leaves'
										value={totalSickLeaves}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card bordered={false}>
									<Statistic
										title='Total Deductions'
										value={totalDeductions}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card bordered={false}>
									<Statistic
										title='Total Payables'
										value={totalPayables}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={4}>
								<Card bordered={false}>
									<Statistic
										title='Total Company Deductions'
										value={totalCompanyDeductionCollection}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
						</Row>
						<br /><br />
						<div style={{ position: 'relative' }}>
							<div className="table-container" ref={tableContainerRef} onScroll={Tablescroll}>
								<table>
									<thead>
										<tr>
											{payrollColumns[payrollType].map((column) => (
												<th key={column.dataIndex}>{column.title}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{filteredEmployees.length === 0 && payrollType === 'Fixed' && (
											<tr>
												{payrollColumns[payrollType].map((column) => (
													<td key={column.dataIndex} style={{ width: column.width }}>
														<Input style={{ width: column.width }} readOnly />
													</td>
												))}
											</tr>
										)}

										{employees.length === 0 && payrollType != 'Fixed' && (
											<tr>
												{payrollColumns[payrollType].map((column) => (
													<td key={column.dataIndex} style={{ width: column.width }}>
														<Input style={{ width: column.width }} readOnly />
													</td>
												))}
											</tr>
										)}


										{payrollType === 'Fixed'
											? filteredEmployees.map((employee, index) => (
												<tr key={index}>
													{payrollColumns[payrollType].map((column) => (
														<td key={column.dataIndex} style={{ width: column.width }}>
															{column.dataIndex === 'customAttributes' ? (
																employee[column.dataIndex].map((attr, attrIndex) => (
																	<div key={attrIndex}>
																		{attr.value}
																	</div>
																))
															) : (
																<Input
																	value={employee[column.dataIndex]}
																	onChange={(event) =>
																		handleInputChange(event, index, column.dataIndex)
																	}
																	style={{ width: column.width }}
																/>
															)}
														</td>
													))}
												</tr>
											))
											: employees.map((employee, index) => (
												<tr key={index}>
													{payrollColumns[payrollType].map((column) => (
														<td key={column.dataIndex} style={{ width: column.width }}>
															{column.dataIndex === 'customAttributes' ? (
																employee[column.dataIndex].map((attr, attrIndex) => (
																	<div key={attrIndex}>
																		{attr.value}
																	</div>
																))
															) : (
																<Input
																	value={
																		(column.dataIndex === "totalPayables" || column.dataIndex === "totalDeductions") &&
																			employee[column.dataIndex] % 1 !== 0
																			? parseFloat(employee[column.dataIndex]).toFixed(2)
																			: employee[column.dataIndex]
																	}
																	onChange={(event) =>
																		handleInputChange(event, index, column.dataIndex)
																	}
																	style={{ width: column.width }}
																/>
															)}
														</td>
													))}
												</tr>
											))}


									</tbody>

								</table>
								<div id='blur' className=""></div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default SalariesAndPayroll;
